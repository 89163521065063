class ls {
    constructor () {}
    set (name, value) {
        let params = {value, type: typeof value}
        localStorage.setItem(`BH_ADM_${name}`, JSON.stringify(params))
    }
    get (name) {
        let params = localStorage.getItem(`BH_ADM_${name}`)
        if (params) {
            params = JSON.parse(params)
            return params.value
        }
        return undefined
    }
    remove (name) {
        localStorage.removeItem(`BH_ADM_${name}`)
    }
}

export default new ls()