import {GetMenus, AdminLogin} from '@/api/index'
// import {MenusDispose} from '@/utils/utils'
// , AdminLogin, AdminUserInfo
const app = {
    state: {
        menusItems: [],
        userInfo: [],
        token: ''
    },
    getters: {
        GET_MENUS (state) {
            return state.menusItems
        },
        GET_USERINFO (state) {
            return state.userInfo
        }
    },
    mutations: {
        SET_MENUS (state, menus) {
            state.menusItems = menus;
        },
        SET_USER_INFO (state, info) {
            state.userInfo = info;
        },
        SET_ADMIN_TOKEN (state, token) {
            state.token = token;
        }
    },
    actions: {
        // 用户登录
        UserInfo ({commit}) {
            let userinfo = localStorage.getItem('ADMIN_USERINFO')
            userinfo = userinfo ? JSON.parse(userinfo) : {}
            console.log(userinfo)
            commit('SET_USER_INFO', userinfo)

            // return new Promise((resolve, reject) => {
            //     AdminUserInfo({}, userinfo.id).then(res => {
            //         if (res.status) {
            //             userinfo = res.data
            //             localStorage.setItem('ADMIN_USERINFO', JSON.stringify(userinfo))
            //             commit('SET_USER_INFO', userinfo)
            //         }
            //         resolve(res)
            //     }).catch(err => {
            //         reject(err)
            //     })
            // })
        },
        AdminLogin ({commit}, params) {
            return new Promise((resolve, reject) => {
                AdminLogin(params).then(res => {
                    commit('SET_USER_INFO', res.data)
                    commit('SET_ADMIN_TOKEN', res.data.identifier)
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        GetMenusItems ({commit}) {
            return new Promise((resolve, reject) => {
                GetMenus().then(res => {
                    let menuItems = res.data;
                    console.log(res.data)
                    if (res.data.length > 0) {
                        commit('SET_MENUS', menuItems);
                        // menuItems = MenusDispose(menuItems)
                        resolve(menuItems)
                    } else {
                        throw new Error('没有菜单访问权限')
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        }

    },
}


export default app;