import { ElMessageBox, ElMessage, ElLoading } from 'element-plus'

class elplus {
    constructor () {
    }
    prompt (...params) {
        return new Promise((resolve, reject) => {
            ElMessageBox.prompt(...params).then((...result) => {
                resolve(...result)
            }).catch((err) => {reject(err)})
        })
    }
    confirm (...params) {
        return new Promise((resolve, reject) => {
            ElMessageBox.confirm(...params).then((...result) => {
                resolve(...result)
            }).catch((err) => {reject(err)})
        })
    }
    alert (...params) {
        return new Promise((resolve) => {
            ElMessageBox.alert(...params).then((...result) => {
                resolve(...result)
            }).catch(() => {})
        })
    }
    msgbox (...params) {
        return new Promise((resolve) => {
            ElMessageBox.msgbox(...params).then((...result) => {
                resolve(...result)
            }).catch(() => {})
        })
    }
    message (...params) {
        return ElMessage(...params)
    }
    loading (...params) {
        return ElLoading.service(...params)
    }
}

export default new elplus()