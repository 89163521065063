<template>
  <div class="el-app-body">
    <router-view></router-view>
  </div>
</template>

<script>
import { provide } from 'vue'
import utils from '@/utils/utils'
import ls from '@/utils/ls'
import elplus from '@/utils/elplus'
import axios from '@/utils/axios'
export default {
  setup () {
    provide('utils', utils)
    provide('ls', ls)
    provide('elplus', elplus)
    provide('axios', axios)
  }
}
</script>

<style lang="scss">
.el-app-body{
  height: 100vh;
}
</style>
