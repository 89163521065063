import axios from "@/utils/axios";
import {menus} from '@/mockjs/menu';

export const GetUserInfo = (data) => {
   return axios.get('/api/articles', data)
}
export const AdminLogin = (data) => {
   return axios.post('login', data)
}

export const AdminLogout = (data) => {
   return axios.post('logout', data)
}

export const AdminUserInfo = (data, id) => {
   return axios.get(`user/detail/${id}`, data)
}
export const GetMenus = () => {
   return new Promise(resolve => {
      setTimeout(() => {
         resolve({
            status: true,
            data: menus
         })
      }, 1000)
   })
//    return axios.get('menu/mine', data)
}