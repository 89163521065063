import router from './router'
import store from './store'
import NProgress from 'nprogress' // 进度条
import 'nprogress/nprogress.css' // 进度条样式

import cookies from 'vue-cookies'

// import { START_LOCATION } from 'vue-router'

// 动态生成路由
const routerPackag = (routers, name = 'BasicLayout') => {
    routers.filter(itemRouter => {
        router.addRoute(name, {
            path: `${itemRouter.path}`,
            name: itemRouter.name,
            component: () => import(`@/${itemRouter.component}`),
            meta: itemRouter.meta
        });
        if (itemRouter.children && itemRouter.children.length) {
            routerPackag(itemRouter.children, name, [itemRouter.name]);
        }
        return true
    })
}

// 路由拦截
router.beforeEach((to, from, next) => {
    let token = cookies.get('ADMIN_TOKEN')
    NProgress.start()
    if (token) {
        if (to.path === '/login') {
            next('/index')
        } else {
            if (!store.getters.GET_MENUS.length) {
                // 用户信息获取
                store.dispatch('UserInfo')
                // 获取路由权限
                store.dispatch('GetMenusItems').then(res => {
                    console.log(res);
                    routerPackag(res, "BasicLayout")
                    // console.log(router.getRoutes())
                    next(to.path) // 防止刷新无法访问到路由
                })
            } else {
                if (to.path === '/') {
                    next('/index')
                } else {
                    next()
                }
            }
        }
    } else {
        if (to.path === '/login') {
            next()
        } else {
            next('/login')
        }
    }
})
// to, from
router.afterEach(() => {
    NProgress.done()
})
