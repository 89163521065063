import { createRouter, createWebHistory } from "vue-router"
import routerDefault from './routes'

let routes = routerDefault;

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router;