
export const menus =  [
    {
        path: '/index',
        component: 'views/main/index',
        
        name: 'index',
        meta: {
            icon: 'GoodsFilled',
            title: '首页'
        },
    },
    {
        path: '/shopping',
        component: 'views/main/shopping',
        name: 'shopping',
        meta: {
            icon: 'GoodsFilled',
            title: '商城管理'
        },
        children: [
            {
                path: '/shopping/list',
                component: 'views/shopping/list',
                name: 'shoppingList',
                meta: {
                    title: '商品列表'
                }
            },
            {
                path: '/shopping/consume',
                component: 'views/shopping/consume',
                name: 'shoppingConsume',
                meta: {
                    title: '消耗记录'
                }
            },
            {
                path: '/shopping/purchase',
                component: 'views/shopping/purchase',
                name: 'shoppingPurchase',
                meta: {
                    title: '购买记录'
                }
            },
        ]
    },
    {
        path: '/answer',
        component: 'views/main/answer',
        icon: 'el-icon-menu',
        name: 'answer',
        meta: {
            icon: 'Flag',
            title: '答题管理'
        },
    },
    {
        path: '/grade',
        component: 'views/main/grade',
        name: 'grade',
        meta: {
            icon: 'HelpFilled',
            title: '等级管理'
        },
    },
    {
        path: '/user',
        component: 'components/pages/index',
        name: 'user',
        meta: {
            icon: 'Avatar',
            title: '用户管理'
        },
        children: [
            {
                path: '/user/employee',
                component: 'views/user/employee',
                name: 'userEmployee',
                meta: {
                    title: '后台用户'
                }
            },
            {
                path: '/user/client',
                component: 'views/user/client',
                name: 'userClient',
                meta: {
                    title: '前台用户'
                }
            }
        ]
    },
    {
        path: '/task',
        component: 'views/main/task',
        name: 'task',
        meta: {
            icon: 'HelpFilled',
            title: '任务管理'
        },
    },
    {
        path: '/card',
        component: 'views/main/card',
        name: 'card',
        meta: {
            icon: 'HelpFilled',
            title: '卡片管理'
        },
    },
    {
        path: '/email',
        component: 'views/main/email',
        name: 'email',
        meta: {
            icon: 'HelpFilled',
            title: '邮件管理'
        },
    },
    {
        path: '/goout',
        component: 'components/pages/index',
        name: 'goout',
        meta: {
            icon: 'HelpFilled',
            title: '外出管理'
        },
        children: [
            {
                path: '/goout/record',
                component: 'views/goout/record',
                name: 'GooutRecord',
                meta: {
                    title: '外出记录'
                }
            }
        ]
    },
]