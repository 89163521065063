
// 默认基本路由
const routerDefault = [
    {
        path: '/',
        component: () => import('@/components/pages/layout'),
        icon: 'el-icon-s-home',
        name: 'BasicLayout'
    },
    {
        path: '/login',
        meta: {
            title: '登录'
        },
        component: () => import('@/views/login')
    },
    {
        path: '/404',
        meta: {
            title: '404'
        },
        component: () => import('@/views/error/404')
    }
]

export default routerDefault;