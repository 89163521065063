import axios from "axios";
import cookies from 'vue-cookies'
import router from '../router/index'
import store from '../store/index'
import { ElMessage, ElMessageBox } from 'element-plus'


var instance = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? '/api/' : process.env.VUE_APP_BASE_API,
    // baseURL: '/api/',
    timeout: 300000,
    headers: {'Accept': 'application/json, text/plain, */*; charset=utf-8'}
});

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    let token = cookies.get('ADMIN_TOKEN')
    if (token) {
        config.headers['identifier'] = token;
    }
    return config;
}, function (error) {
// 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    if (response.data.ret == 20001) {
        ElMessageBox.confirm('登录验证失败, 请重新登录！！！', '提示', {
            confirmButtonText: '确定',
            showClose: false,
            showCancelButton: false,
            closeOnPressEscape: false,
            closeOnClickModal: false,
            closeOnHashChange: false,
            type: 'warning',
          }).then(() => {
            cookies.remove('ADMIN_TOKEN')
            store.commit('SET_MENUS', [])
            router.push('/login')
          }).catch(() => {})
        // return response.data
    }
    if (response.data.ret == 10000) {
        ElMessage.error(response.data.msg);
        return response.data;
    } else {
        return response.data;
    }
}, function (error) {
// 对响应错误做点什么
    return Promise.reject(error);
});


export default {
    get (url, data = {}) {
        return instance({ method: 'get', url, params: data })
    },
    post (url, data = {}) {
        return instance({ method: 'post', url, data })
    }
};